/*Cookie Banner*/
/* Cookie Banner media 1*/
@media screen and (max-width:850px){
    .blackcookiebanner{
      position:fixed;
      width:100vw;
      height:100vh;
      z-index:9;
      background-color:black;
      opacity:0.8;
    }
    .cookiebanner{
      position:fixed;
      top:calc(100vh / 2 - 25vh );
      left: calc(100vw / 2 - 50vw);
      display:flex;
      flex-direction:column;
      height:min-content;
      max-width:fit-content;
      width:80vw;
      padding:2em;
      color: rgb(24, 99, 194);
      font-family:Montserrat;
      background-color: rgb(238,241,244);
      z-index:10;
      scale:0.8;
    }
    
    .cookiebanner .actions{
      display:flex;
      padding: 1em 0 1em 0;
      margin-right:-1em;
      align-self:end;
    
    }
    .cookiebanner button{
      background-color:rgb(24,99,194);
      font-family: Montserrat;
      color:white;
      border-style:none;
      font-weight:bold;
      font-size:medium;
      padding: 0.5em 1em 0.5em 1em;
      margin-left:1em;
      cursor:pointer;
      
    }
  
  }
  
  /*Cookie Banner media 2*/
  @media screen and (min-width:850px){
    .blackcookiebanner{
      position:fixed;
      width:100vw;
      height:100vh;
      z-index:9;
      background-color:black;
      opacity:0.8;
    }
    .cookiebanner{
      position:fixed;
      top:calc(100vh / 2 - 20vh );
      left: calc(100vw / 2 - 20vw);
      display:flex;
      flex-direction:column;
      height:min-content;
      width:40vw;
      padding:2em;
      color: rgb(24, 99, 194);
      font-family:Montserrat;
      background-color: rgb(238,241,244);
      z-index:10;
      
    }
    
    .cookiebanner .actions{
      display:flex;
      padding:1em;
      align-self:end;
    
    }
    .cookiebanner button{
      background-color:rgb(24,99,194);
      font-family: Montserrat;
      color:white;
      border-style:none;
      font-weight:bold;
      font-size:medium;
      padding: 0.5em 1em 0.5em 1em;
      margin-left:1em;
      cursor:pointer;
      
    }
  
  
  }
/* Imports*/


@font-face {
  font-family: ArchivoNarrow;
  src: url(./assets/ArchivoNarrow.ttf);
}

@font-face {
  font-family: ArchivoBlack;
  src: url(./assets/Archivo-Black.ttf);
}
@font-face {
  font-family: Montserrat;
  src: url(./assets/Montserrat.ttf);
}

@font-face {
  font-family: Montserrat-Thin;
  src: url(./assets/Montserrat-Thin.ttf);
}

@font-face {
  font-family: Montserrat-Light;
  src: url(./assets/Montserrat-Light.ttf);
}

@font-face {
  font-family: Cooper-Hewitt-Light;
  src: url(./assets/CooperHewitt-Light.ttf);
}


/* General */

/*General media 1 */
@media screen and (max-height:520px) and (min-width:460px){
  body,html{
    
    
    position:relative;
    
  }
}
@media screen and (max-width:460px){
  body,html{
    overflow-x:hidden;
    position:relative;
    
  }
  
  p{
    margin:0;
    
  }
  a{
    text-decoration:none;
    color:white;
  }
 
  .black{
    font-family:Montserrat;
  }
  
  h1{
    font-weight:400;
    text-transform:uppercase;
    text-align:center;
    
  }
  h2 {
    font-weight:200;
    text-align:center;
  
  }
  .button{
    border-style:none;
    border-radius:2em;
    background-color:rgb(84, 175, 230);
    color:white;
    font-weight:bold;
    padding:0.5em 1.5em 0.5em 1.5em;
    font-family:Montserrat;
    margin:2em;
    font-size:3.2vw;
    width:fit-content;
    align-self:center;
    cursor:pointer;
    
  }
  
  
  .backgroundlogo{
    position: absolute;
    left: 20vw;
    top: -40vh;
  }
  .backgroundlogo img{
    height:90vh;
    
  }
  @media (prefers-reduced-motion: no-preference){
    .backgroundlogo{
      animation: Logo-spin infinite 20s linear;
    }
    .backgroundlogo2{
      animation: Logo-spin infinite 20s linear;
    }
    .backgroundlogo3{
      animation: Logo-spin infinite 20s linear;
    }
  }
  @keyframes Logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  
}

/* General media 2*/
@media screen and (min-width:460px){
body{
  overflow-x:hidden;
  position:relative;
  
}

p{
  margin:0;
  
}
a{
  text-decoration:none;
  color:white;
}
.black{
  font-family:Montserrat;
}

h1{
  font-weight:400;
  text-transform:uppercase;
  text-align:center;
  
}
h2 {
  font-weight:200;
  text-align:center;

}
.button{
  border-style:none;
  border-radius:2em;
  background-color: rgb(84,175,230);
  color:white;
  font-weight:bold;
  padding:0.5em 1.5em 0.5em 1.5em;
  font-family:Montserrat;
  margin:2em;
  width:fit-content;
  align-self:center;
  cursor:pointer;
  
}


.backgroundlogo{
  position: absolute;
  left: 30vw;
  top: -40vh;
}
.backgroundlogo img{
  height:90vh;
  
}
@media (prefers-reduced-motion: no-preference){
  .backgroundlogo{
    animation: Logo-spin infinite 20s linear;
  }
  .backgroundlogo2{
    animation: Logo-spin infinite 20s linear;
  }
  .backgroundlogo3{
    animation: Logo-spin infinite 20s linear;
  }
}
@keyframes Logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


}
/* General media 3 */
@media screen and (min-width:660px){
  body{
    overflow-x:hidden;
    position:relative;
 
  }
  
  p{
    margin:0;
    
  }
  a{
    text-decoration:none;
    color:white;
  }
  .black{
    font-family:Montserrat;
  }
  
  h1{
    font-weight:400;
    text-transform:uppercase;
    text-align:center;
    
  }
  h2 {
    font-weight:200;
    text-align:center;
  
  }
  .button{
    border-style:none;
    border-radius:2em;
    background-color: rgb(84, 175, 230);
    color:white;
    font-weight:bold;
    padding:0.5em 1.5em 0.5em 1.5em;
    font-family:Montserrat;
    margin:2em;
    width:fit-content;
    align-self:center;
    cursor:pointer;
    
  }
  
  
  .backgroundlogo{
    position: absolute;
    left: 55vw;
    top: -40vh;
  }
  .backgroundlogo img{
    height:95vh;
    
  }
  @media (prefers-reduced-motion: no-preference){
    .backgroundlogo{
      animation: Logo-spin infinite 20s linear;
    }
    .backgroundlogo2{
      animation: Logo-spin infinite 20s linear;
    }
    .backgroundlogo3{
      animation: Logo-spin infinite 20s linear;
    }
  }
  @keyframes Logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  
}
/* General media 4 */
@media screen and (min-width:1200px){
  body{
    overflow-x:hidden;
    position:relative;
    
  }
  
  p{
    margin:0;
    
  }
  a{
    text-decoration:none;
    color:white;
  }
  .black{
    font-family:Montserrat;
  }
  
  h1{
    font-weight:400;
    text-transform:uppercase;
    text-align:center;
    
  }
  h2 {
    font-weight:200;
    text-align:center;
  
  }
  .button{
    border-style:none;
    border-radius:2em;
    background-color: rgb(84, 175, 230);
    color:white;
    font-weight:bold;
    padding:0.5em 1.5em 0.5em 1.5em;
    font-family:Montserrat;
    font-size:medium;
    margin:2em;
    width:fit-content;
    align-self:center;
    cursor:pointer;
    
  }
  
  
  .backgroundlogo{
    position: absolute;
    left: 55vw;
    top: -40vh;
  }
  .backgroundlogo img{
    height:100vh;
    
  }
  @media (prefers-reduced-motion: no-preference){
    .backgroundlogo{
      animation: Logo-spin infinite 20s linear;
    }
    .backgroundlogo2{
      animation: Logo-spin infinite 20s linear;
    }
    .backgroundlogo3{
      animation: Logo-spin infinite 20s linear;
    }
  }
  @keyframes Logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
}















/* Others*/


#invierschritten{
  padding: 0 5% 0 5%;
  height:fit-content;

}

#invierschritten .text{
  text-align:center;
  padding: 0 0 3em 0 ;
}

#invierschritten h2{
  font-weight:400;
  text-transform:uppercase;
}

.grid{

  display:grid;
  grid-template-columns:1fr 1fr;
  grid-template-rows:1fr 1fr 1fr 1fr 1fr;
  gap:1em;
  margin: 0 2em 2em 2em;
  height:min-content;

}

#one{
  padding-left:15%;
  grid-column-start:1;
  grid-column-end:2;
  grid-row-start:1;
  grid-row-end:3;
  
}
#two{
  padding-left:15%;
  grid-column-start:2;
  grid-column-end:3;
  grid-row-start:2;
  grid-row-end:4;
}
#three{
  padding-right:15%;
  grid-column-start:1;
  grid-column-end:2;
  grid-row-start:3;
  grid-row-end:5;

}
#four{
  padding-right:15%;
  grid-column-start:2;
  grid-column-end:3;
  grid-row-start:4;
  grid-row-end:6;

}

.bulletpoint{
  background-color:rgb(220, 222, 224);
  border-radius:2em;
  padding:0.2em 1.5em 1.5em 1.5em;
  
}
.team{

  display:flex;
  flex-direction:column;
  justify-content:center;
  height:calc(100vh - 65px);
  
  color:rgb(24,99,194);
  font-family:Montserrat;
}

.team img{
  height:40%;
  border-radius:50%;
  
}

.allmembers{
  height:50vh;
  display:flex;
  justify-content:space-evenly;
  text-align:center;
  /*height: 80vh;*/
}
.allmembers h3{
  text-transform:uppercase;
  font-weight:400;

}

.member{
  width:25%;
}

/*#kontaktformular{
  height:calc(100vh - 65px);
  align-items:center;
  font-family:Montserrat;
  color: rgb(24, 99, 194);
  display:flex;
  flex-direction:column;
  justify-content:center;
}*/





 #Partner, .Impressum, .Datenschutz{
  height: calc(100vh - 65px);
  display:flex;
  flex-direction:column;
  justify-content:center;
}


input[type= "checkbox"]{
  margin:0;
  padding:0;
  box-sizing:none;

}
input[type= "submit"]{
  background-color:rgb(24, 99, 194);
  font-family: Montserrat;
  color:white;

}

input[type= "submit"]:hover, input[type= "submit"]:visited, input[type= "submit"]:link , input[type= "submit"]::after{
  background-color:rgb(220, 222, 224);
  color:rgb(24, 99, 194);
}

.anfrageButton{
  border-style:none;
  border-radius:2em;
  background-color: rgb(78,136,143);
  color:white;
  font-weight:bold;
  padding:0.5em 1.5em 0.5em 1.5em;
  font-family:Montserrat;
  margin:1.1em 0 0 0;
  
}








@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


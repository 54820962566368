
/*Datenschutz*/

#datenschutz {
    align-items:start;
    margin-left:3em;
    text-align:left;
    padding:1em 1em 2em 1em ;
    width:70%;
    
  }
  #datenschutz h1{
    margin-left:0;
  }
  #datenschutz h3{
    margin:0;
  }
  #datenschutz a{
    color:rgb(24, 99, 194);
    text-decoration:underline;
  }
  
  #einwilligungserklärung{
    border:solid 2px rgb(24, 99, 194);
    padding:1em;
    font-style:italic;
  }
  #einwilligungserklärung h2{
    font-size:large;
    font-weight:bold;
  }
  
  
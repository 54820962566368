
/* Home Wrapper all */

/* Home Wrapper All media 1*/
@media screen and (max-width:660px){
    .wrapper_dark{
      position: relative;
      display:flex;
      flex-direction:column;
      justify-content:space-evenly;
      min-height:min-content;
      height:min-content;
      align-items:center;
      text-align:center;
      font-family:Montserrat;
      z-index:1;
      color:white;
      background-color: rgb(18,32,58);
      font-size:3vw;
    }
    
    .wrapper_dark h1{
      color:white;
      font-size:6vw;
      word-wrap:break-word;
      margin:1.5em;
      padding-top:1em;
      
    }
    .wrapper_light{
      position: relative;
      display:flex;
      flex-direction:column;
      justify-content:space-evenly;
      min-height:min-content;
      max-height:fit-content;
      height:min-content;
      align-items:center;
      text-align:center;
      font-family:Montserrat;
      z-index:1;
      color: rgb(24, 99, 194);
      background-color: white;
      font-size:3vw;
    }
    
    .wrapper_light h1{
      color: rgb(24, 99, 194);
      font-size:6vw;
      word-wrap:break-word;
      margin:1.5em;
      padding-top:1em;
    }
    #energieversorgung{
      z-index:2;
      min-height:100vh;
    }
    
    #energieversorgung .content {
      display:flex;
      flex-direction:column;
      align-items:center;
      text-align:center;
      justify-content:center;
     
      padding: 0 10% 0 10%;
      
    }
    #energieversorgung .text{
      padding: 0 1% 10% 1%;
      width:80%;
     
    }
    
    #energieversorgung .image{
      padding: 0 1% 0 1%;
      margin:0;
      width:100%;
    }
    #energieversorgung .image img{
      width:90%;
      
    }
  
  
  
    #mathematisch .content{
      display:flex;
      flex-direction:column;
      align-items:center;
      justify-content:center;
      min-height:100vh;
      padding: 0 10% 0 10%;
  
    }
    #mathematisch .image{
      z-index:-1;
      width:90%;
      display:flex;
      justify-content:center;
      
    }
    #mathematisch .image img {
      width:100%;
      height:100%;
    }
    #mathematisch .text{
      width:85%;
      z-index:1;
      padding:2em 0 2em 0 ;
    }
    .backgroundlogo2{
      position: absolute;
      left: -15vw;
      top:-10vh;
      z-index:-10;
    }
    .backgroundlogo2 img{
      height:40vh;
      z-index:-10;
    }
    #grauerKreis{
      position: absolute;
      left:-15vw;
      top:-10vh;
      z-index:-11;
      
     
    }
    #grauerKreis img{
      height:41vh;
      z-index:-11;
  
    }
  
  
  
    
    
  #fuenfgruende {
    position:relative;
    overflow-x:hidden;
    min-height:85vh;
  }
  #fuenfgruende h1{
    width:80%;
    
    text-align: center;
  }
 
  #fuenfgruende .list{
    display:flex;
    justify-content:space-between;
    width:100vw;
    align-items:center;
    height:35%;
    overflow-x:hidden;
  }

  #fuenfgruende .list h2{
    font-size:3.25vw;
    scale:0.95

  }
  #fuenfgruende .list p{
    font-size:3vw;
    scale:0.95
  }
  .fuenfgruende_box{
    background-color:rgb(24, 99, 194);
    width:calc(130vw / 3 - 2em);
    height:30vh;
    
    border-radius:2em;
    padding:2em 2em 3em 2em;
    box-shadow:0.3em 0.3em 0.3em black;
    display:flex;
    flex-direction:column;
    justify-content:center;
    margin:2em 0em 3em 0em;
  }
  .fuenfgruende_box h2{
    margin:1em 0 1em 0;
    font-weight:bold;
    font-size:large;
  }

  #fuenfgruende_click_right{
    background-color:transparent;
    width:calc(130vw / 3 - 2em);
    height:28vh;
    position:absolute;
    left:50vw;
    z-index:11;
    cursor:pointer;
    border-radius:2em;
    padding:2em 2em 3em 2em;
   
  }
  #fuenfgruende_click_left{
    background-color:transparent;
    width:calc(130vw / 3 - 2em);
    height:28vh;
    position:absolute;
    left:0;
    z-index:11;
    cursor:pointer;
    border-radius:2em;
    padding:2em 2em 3em 2em;
   
  }

  #fuenfgruende_box_1{
    position:sticky;
    left:25vw;
    scale:1;
    opacity:95%;
    z-index:9;
    transition:scale 1.5s,left 1.5s;
    
  }


  #fuenfgruende_box_2{
    position:absolute;
    left:50vw;
    scale:0.8;
    opacity:70%;
    z-index:8;
    cursor:pointer;
    transition:scale 1.5s,left 1.5s;
    
    
  }

  #fuenfgruende_box_3{
    position:absolute;
    left:40vw;
    scale:0.58;
    opacity:70%;
    z-index:7;
    cursor:pointer;
    transition:scale 1.5s,left 1.5s;
    
  }
  #fuenfgruende_box_4{
    position:absolute;
    left:10vw;
    scale:0.58;
    opacity:70%;
    z-index:7;
  
    transition:scale 1.5s,left 1.5s;
    
  }
  #fuenfgruende_box_5{
    position:absolute;
    left:0vw;
    scale:0.8;
    opacity:70%;
    z-index:8;
    cursor:pointer;
    transition:scale 1.5s,left 1.5s;
  }
 
 


  
    #vierschritte{
      padding: 1em 0;
  
    }
    #vierschritte .content{
      display:flex;
      flex-direction:column;
      margin:0 2em;
      height: min-content;
      align-items:center;
      
    }
    #vierschritte .text{
      display:flex;
      flex-direction:column;
      align-items:center;
      justify-content:center;
      min-height:5vh;
      height:max-content;
      min-width:50vw;
      width:fit-content;
      margin:1em;
      box-shadow:5px 5px 10px grey;
      border-radius:2em;
      padding:1em;
    }
    #vierschritte .image{
    
    display:flex;
    justify-content:center;
    
    }
    #vierschritte img{
      width:40%;
    }
    #vierschritte h3{
      font-weight:normal;
    }
   
    
    #zahlen {
      height:80vh;
      padding:0 0 2em 0;
      min-height:fit-content;
      
      
    }
  
    #zahlen h2 {
      font-weight:bold;
      color:rgb(84, 175, 230)
      
    }
  
  
    #zahlen .list{
      width:80%;
      display:flex;
      flex-direction: column;
      text-align:center;
      color: white;
      font-family:Montserrat;
      justify-content:space-between;
    
    }
   
    #partner{
      position:relative;
      
      
    }
    #partner .bar{
      display:grid;
      grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
      align-items:center;
      width:50vw;
      gap:1%;
      
      padding-bottom:3em;
      margin-bottom:1em;
      align-items:center;
      
    }
    
    #partner .bar .image{
      width:80%;
      height:min-content;
      
      
    }
    #partner .bar .image img{
      width:100%;
      height:100%;
    }
    #logo img{
      
      scale:0.7;
      align-items:center;
    }
  
  
    #team{
      background-color:rgb(238,241,244);
      height:min-content;
      min-height:100vh;
    }
  
   
   
    #team .content{
      display:flex;
      flex-direction:column;
      text-align:center;
      align-items:center;
      padding: 0 10% 0 10%;
    }
    
    #team .text{
      display:flex;
      flex-direction:column;
      width:80%;
      padding:1em;
    
    }
    #team .content .image{
      width:90%;
      display:flex;
      justify-content:space-around;
      margin:3em 0 2em 0;
      
    }
    
    #team img{
      width:80%;
    }
    
    
    #kontaktformular{
      height:min-content;
      min-height:100vh;
      
    }
    #kontaktformular .content{
      display:flex;
      flex-direction:column;
      height:50%;
      align-items:center;
    }
    #kontaktformular .text{
      
      width:68%;
      text-align:center;
    }
    #form{
      width:100%;
      margin:1em;
    }
    
    #vorname, #nachname, #email, #unternehmen{
      border-radius:0.8em;
      border-color:rgb(24, 99, 194);
      border-width:1px;
      width:calc(40% - 1em);
      margin:0.5em;
      color: rgb(24, 99, 194);
      font-family: Montserrat;
      padding:0.3em;
      font-size:0.7em;
      
    }
    #nachricht{
      border-radius:1em;
      border-color:rgb(24, 99, 194);
      border-width:1px;
      margin:0 0.5em;
      width:calc(80% - 1em);
      height:30%;
      color: rgb(24, 99, 194);
      font-family: Montserrat;
      min-height:100px;
      padding:0.3em;
      font-size:0.7em;
      
    }
    #datenschutzeinverständnis{
      word-wrap:break-word;
      margin:1em;
     
      
     
    }
    
    #nachricht::placeholder{
      color: rgb(24, 99, 194);
      font-family: Montserrat;
      
    }
    
    #vorname::placeholder ,#nachname::placeholder, #email::placeholder, #unternehmen::placeholder{
      color: rgb(24, 99, 194);
      font-family: Montserrat;
    }
    
    
    
    .msg{
      color: red;
      font-size:smaller;
    }
    
      
  
  
  
      
  
  }
  /* Home Wrapper All media 2*/
  @media screen and (max-height:520px) {
    .wrapper_dark{
      position: relative;
      display:flex;
      flex-direction:column;
      justify-content:space-evenly;
      min-height:100vh;
      width:100vw;
      height:fit-content;
      align-items:center;
      text-align:center;
      font-family:Montserrat;
      z-index:1;
      color:white;
      background-color: rgb(18,32,58);
      font-size:1.7vw;
    }
    
    .wrapper_dark h1{
      color:white;
      margin:1.5em;
      font-size:3.4vw;
      padding-top:1em;
    }
    .wrapper_light{
      position: relative;
      display:flex;
      flex-direction:column;
      justify-content:space-evenly;
      min-height:100vh;
      height:fit-content;
      align-items:center;
      text-align:center;
      font-family:Montserrat;
      z-index:1;
      color: rgb(24, 99, 194);
      background-color: white;
      font-size:1.7vw;
    }
    
    .wrapper_light h1{
      color: rgb(24, 99, 194);
      margin:1.5em;
      font-size:3.4vw;
      padding-top:1em;
    }
    
    
    #energieversorgung .content {
      display:flex;
      align-items:center;
      text-align:center;
      padding: 0 10% 0 10%;
      font-size:1.7vw;
    }
    
    #energieversorgung .text{
      padding: 0 1% 0 1%;
      width:45%;
     
    }
    
    #energieversorgung .image{
      padding: 0 1% 0 1%;
      margin:0;
      width:50%;
    }
    #energieversorgung .image img{
      width:65%;
    }
    #mathematisch .content{
      display:flex;
      align-items:center;
      justify-content:center;
      padding: 0 10% 0 10%;
      font-size:1.7vw;
    
    }
    #mathematisch .image{
      width:50%;
      z-index:-1;
    }
    #mathematisch .image img {
      width:90%;
      height:90%;
    }
    #mathematisch .text{
      width:60%;
      display:flex;
      flex-direction:column;
      align-items:center;
      z-index:1;
      padding-bottom:1em;
    }
    #mathematisch .text p {
      width:100%;
  
    }
    .backgroundlogo2{
      position: absolute;
      left: -5vw;
      top:35vh;
      z-index:-10;
    }
    .backgroundlogo2 img{
      width:50vw;
    }
    #grauerKreis{
      position: absolute;
      left: -5vw;
      top:35vh;
      z-index:-11;
    }
    #grauerKreis img{
      width:50vw;
      
  
    }
    
  
  #fuenfgruende {
    position:relative;
    overflow-x:hidden;
  }
  #fuenfgruende h1{
    width:70%;
    text-align: center;
  }
  #fuenfgruende .text{
    width:60%;
    
  }
  #fuenfgruende .list{
   

    display:flex;
    justify-content:space-between;
    width:100vw;
  
    align-items:center;
    height:35%;
    overflow-x:hidden;
  }
  .fuenfgruende_box{
    background-color:rgb(24, 99, 194);
    width:calc(130vw / 3 - 2em);
    height:28vh;
    
    border-radius:2em;
    padding:2em 2em 3em 2em;
    box-shadow:0.3em 0.3em 0.3em black;
    display:flex;
    flex-direction:column;
    justify-content:center;
    margin:2em 0em 3em 0em;
  }
  .fuenfgruende_box h2{
    margin:1em 0 1em 0;
    font-weight:bold;
    font-size:large;
  }

  #fuenfgruende_click_right{
    background-color:transparent;
    width:calc(130vw / 3 - 2em);
    height:28vh;
    position:absolute;
    left:50vw;
    z-index:11;
    cursor:pointer;
    border-radius:2em;
    padding:2em 2em 3em 2em;
   
  }
  #fuenfgruende_click_left{
    background-color:transparent;
    width:calc(130vw / 3 - 2em);
    height:28vh;
    position:absolute;
    left:0;
    z-index:11;
    cursor:pointer;
    border-radius:2em;
    padding:2em 2em 3em 2em;
   
  }

  #fuenfgruende_box_1{
    position:sticky;
    left:25vw;
    scale:1;
    opacity:95%;
    z-index:9;
    transition:scale 1.5s,left 1.5s;
    
  }


  #fuenfgruende_box_2{
    position:absolute;
    left:50vw;
    scale:0.8;
    opacity:70%;
    z-index:8;
    cursor:pointer;
    transition:scale 1.5s,left 1.5s;
    
    
  }

  #fuenfgruende_box_3{
    position:absolute;
    left:40vw;
    scale:0.58;
    opacity:70%;
    z-index:7;
    cursor:pointer;
    transition:scale 1.5s,left 1.5s;
    
  }
  #fuenfgruende_box_4{
    position:absolute;
    left:10vw;
    scale:0.58;
    opacity:70%;
    z-index:7;
  
    transition:scale 1.5s,left 1.5s;
    
  }
  #fuenfgruende_box_5{
    position:absolute;
    left:0vw;
    scale:0.8;
    opacity:70%;
    z-index:8;
    cursor:pointer;
    transition:scale 1.5s,left 1.5s;
  }
  
  
  #vierschritte .content{
    display:flex;
    flex-direction:row;
    margin:0 2em;
    height: min-content;
    font-size:smaller;
  }
  #vierschritte .text{
    display:flex;
    flex-direction:column;
    align-items:center;
    width:calc(100vw / 4 - 6em);
    min-height:30vh;
    height:min-content;
    margin:1em;
    box-shadow:5px 5px 10px grey;
    border-radius:2em;
    padding:1em;
  }
  
  
  #vierschritte .image{
  display:flex;
  justify-content:center;
  }
  #vierschritte img{
    width:50%;
  }
  #vierschritte h3{
    font-weight:normal;
  }
  
  #zahlen {
    height:70vh;
    min-height:70vh;
  }
  
  
  #zahlen h2 {
    font-weight:bold;
    color:rgb(84, 175, 230);
    
  }
  
  
  #zahlen .list{
    width:95%;
    display:flex;
    text-align:center;
    color: white;
    font-family:Montserrat;
    justify-content:space-between;
    padding: 0 0 2em 0 ;
  
  
  }
  
  #partner{
    height:fit-content;
    min-height:60vh;
    max-height:100vh;
  }
  
  #partner .bar{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap:5%;
    width:90vw;
    margin-bottom:2em;
  }
  
  #partner .bar .image{
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin:0 1em 0 1em;
  }
  #partner .bar .image img{
    width:70%;
    
  }
  #logo img{
    height:100%;
    width:100%;
    scale:0.7;
    align-items:center;
  }
  
  
  #team{
    background-color:rgb(238,241,244);
    
  }
  
  #team .content{
    display:flex;
    text-align:center;
    align-items:center;
    padding: 0 10% 0 10%;
  }
  
  #team .text{
    display:flex;
    flex-direction:column;
    width:50%;
  
  }
  #team .content .image{
    width:50%;
    margin:3em 0 2em 0;
    display:flex;
    justify-content:space-around;
    
  }
  
  #team img{
    width:60%;
  }
  
  
  
  #kontaktformular .content{
    display:flex;
    height:50%;
  }
  #kontaktformular .text{
    margin:0 5em 0 5em;
    width:calc(50vw - 10em);
    text-align:center;
  }
  #form{
    width:50%;
    height:min-content;
    padding:1em;
  }
  
  #vorname, #nachname, #email, #unternehmen{
    border-radius:0.8em;
    border-color:rgb(24, 99, 194);
    border-width:1px;
    width:calc(40% - 2em);
    margin:1em;
    color: rgb(24, 99, 194);
    font-family: Montserrat;
    padding:0.3em;
    font-size:0.7em;
  }
  #nachricht{
    border-radius:1em;
    border-color:rgb(24, 99, 194);
    border-width:1px;
    margin:0 1em;
    width:calc(80% - 2em);
    height:30%;
    min-height:100px;
    color: rgb(24, 99, 194);
    font-family: Montserrat;
    padding:0.3em;
    font-size:0.7em;
    
  }
  #datenschutzeinverständnis{
    word-wrap:break-word;
    margin:1em;
  
    
    
  }
  
  #nachricht::placeholder{
    color: rgb(24, 99, 194);
    font-family: Montserrat;
    
  }
  
  #vorname::placeholder ,#nachname::placeholder, #email::placeholder, #unternehmen::placeholder{
    color: rgb(24, 99, 194);
    font-family: Montserrat;
  }
  
  
  
  .msg{
    color: red;
    font-size:smaller;
  }
  }
  
  
  
  /* Home Wrapper All media 3*/
  @media screen and (min-width:660px) and (min-height:520px) {
    .wrapper_dark{
      position: relative;
      display:flex;
      flex-direction:column;
      justify-content:space-evenly;
      min-height:80vh;
      height:fit-content;
      align-items:center;
      text-align:center;
      font-family:Montserrat;
      z-index:1;
      color:white;
      background-color: rgb(18,32,58);
      font-size:1.5vw;
      
    }
    
    .wrapper_dark h1{
      color:white;
      margin:1.5em;
      font-size:2.5vw;
      
    }
    .wrapper_light{
      position: relative;
      display:flex;
      flex-direction:column;
      justify-content:space-evenly;
      min-height:70vh;
      height:fit-content;
      align-items:center;
      text-align:center;
      font-family:Montserrat;
      z-index:1;
      color: rgb(24, 99, 194);
      background-color: white;
      font-size:1.5vw;
    }
  
    .wrapper_ligth{
      font-size:1.5vw;
    }
    
    .wrapper_light h1{
      color: rgb(24, 99, 194);
      margin:2em;
      font-size:2.5vw;
    }
    
    
    #energieversorgung .content {
      display:flex;
      align-items:center;
      text-align:center;
      padding: 0 10% 0 10%;
      
    }
  
    #energieversorgung .text{
      padding: 0 1% 0 1%;
      width:45%;
      
    }
    
    #energieversorgung .image{
      padding: 0 1% 0 1%;
      margin:0;
      width:50%;
    }
    #energieversorgung .image img{
      width:65%;
    }
    #mathematisch .content{
      display:flex;
      align-items:center;
      justify-content:center;
      padding: 0 10% 0 10%;
     
    
    }
    #mathematisch .image{
      width:50%;
      z-index:-1;
    }
    #mathematisch .image img {
      width:90%;
      height:90%;
    }
    #mathematisch .text{
      width:50%;
      display:flex;
      flex-direction:column;
      align-items:center;
      z-index:1;
     
    }
    #mathematisch .text p {
      width:80%;
  
    }
    .backgroundlogo2{
      position: absolute;
      left: -5vw;
      top:35vh;
      z-index:-10;
    }
    .backgroundlogo2 img{
      width:50vw;
    }
    #grauerKreis{
      position: absolute;
      left: -5vw;
      top:35vh;
      z-index:-11;
    }
    #grauerKreis img{
      width:50vw;
      
  
    }
    
 
  #fuenfgruende {
    position:relative;
    overflow-x:hidden;
  }
  #fuenfgruende h1{
    width:70%;
    text-align: center;
  }
 
  #fuenfgruende .list{
   

    display:flex;
    justify-content:space-between;
    width:100vw;
  
    align-items:center;
    height:35%;
    overflow-x:hidden;
  }
  #fuenfgruende .list h2{
    scale:1.1;
  }
  .fuenfgruende_box{
    background-color:rgb(24, 99, 194);
    width:calc(130vw / 3 - 2em);
    height:28vh;
    
    border-radius:2em;
    padding:2em 2em 3em 2em;
    box-shadow:0.3em 0.3em 0.3em black;
    display:flex;
    flex-direction:column;
    justify-content:center;
    margin:2em 0em 3em 0em;
  }
  .fuenfgruende_box h2{
    margin:1em 0 1em 0;
    font-weight:bold;
    font-size:large;
  }

  #fuenfgruende_click_right{
    background-color:transparent;
    width:calc(130vw / 3 - 2em);
    height:28vh;
    position:absolute;
    left:50vw;
    z-index:11;
    cursor:pointer;
    border-radius:2em;
    padding:2em 2em 3em 2em;
   
  }
  #fuenfgruende_click_left{
    background-color:transparent;
    width:calc(130vw / 3 - 2em);
    height:28vh;
    position:absolute;
    left:0;
    z-index:11;
    cursor:pointer;
    border-radius:2em;
    padding:2em 2em 3em 2em;
   
  }

  #fuenfgruende_box_1{
    position:sticky;
    left:25vw;
    scale:1;
    opacity:95%;
    z-index:9;
    transition:scale 1.5s,left 1.5s;
    
  }


  #fuenfgruende_box_2{
    position:absolute;
    left:50vw;
    scale:0.8;
    opacity:70%;
    z-index:8;
    cursor:pointer;
    transition:scale 1.5s,left 1.5s;
    
    
  }

  #fuenfgruende_box_3{
    position:absolute;
    left:40vw;
    scale:0.58;
    opacity:70%;
    z-index:7;
    cursor:pointer;
    transition:scale 1.5s,left 1.5s;
    
  }
  #fuenfgruende_box_4{
    position:absolute;
    left:10vw;
    scale:0.58;
    opacity:70%;
    z-index:7;
  
    transition:scale 1.5s,left 1.5s;
    
  }
  #fuenfgruende_box_5{
    position:absolute;
    left:0vw;
    scale:0.8;
    opacity:70%;
    z-index:8;
    cursor:pointer;
    transition:scale 1.5s,left 1.5s;
  }
  
 
  #vierschritte .content{
    display:flex;
    flex-direction:row;
    margin:0 2em;
    height:max-content;
    max-height:100vh;
    
  }
  #vierschritte .text{
    display:flex;
    flex-direction:column;
    align-items:center;
    width:calc(100vw / 4 - 6em);
    min-height:23vh;
    height:min-content;
    margin:1em;
    box-shadow:5px 5px 10px grey;
    border-radius:2em;
    padding:1em;
  }
  
  
  #vierschritte .image{
  display:flex;
  justify-content:center;
  }
  #vierschritte img{
    width:50%;
  }
  #vierschritte h3{
    font-weight:normal;
    font-size:smaller;
  }
  
  #zahlen {
    height:55vh;
    min-height:55vh;
  }
  
  
  #zahlen h2 {
    font-weight:bold;
    color:rgb(84, 175, 230);
    
  }
  
  
  #zahlen .list{
    width:80%;
    display:flex;
    text-align:center;
    color: white;
    font-family:Montserrat;
    justify-content:space-between;
    padding: 0 0 2em 0 ;
  
  
  }
  
  #partner{
    height:fit-content;
    min-height:50vh;
    max-height:100vh;
  }
  
  #partner .bar{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap:5%;
    width:90vw;
    margin-bottom:2em;
  }
  
  #partner .bar .image{
    width:100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    margin:0 1em 0 1em;
  }
  #partner .bar .image img{
    width:70%;
    
  }
  #logo img{
    height:100%;
    width:100%;
    scale:0.7;
    align-items:center;
  }
  
  
  #team{
    background-color:rgb(238,241,244);
  }
  
  #team .content{
    display:flex;
    text-align:center;
    align-items:center;
    padding: 0 10% 0 10%;
  }
  
  #team .text{
    display:flex;
    flex-direction:column;
    width:50%;
   
    
  
  }
  #team .content .image{
    width:50%;
    margin:3em 0 2em 0;
    display:flex;
    justify-content:space-around;
    
    
  }
  
  #team img{
    width:70%;
  }
  
  
  
  #kontaktformular .content{
    display:flex;
    height:50%;
  }
  #kontaktformular .text{
    margin:0 5em 0 5em;
    width:calc(50vw - 10em);
    text-align:center;
  }
  #form{
    width:50%;
    height:min-content;
    padding:1em;
  }
  
  #vorname, #nachname, #email, #unternehmen{
    border-radius:0.8em;
    border-color:rgb(24, 99, 194);
    border-width:1px;
    width:calc(40% - 2em);
    margin:1em;
    color: rgb(24, 99, 194);
    font-family: Montserrat;
    padding:0.3em;
    font-size:0.7em;
  }
  #nachricht{
    border-radius:1em;
    border-color:rgb(24, 99, 194);
    border-width:1px;
    margin:0 1em;
    width:calc(80% - 2em);
    height:30%;
    min-height:100px;
    color: rgb(24, 99, 194);
    font-family: Montserrat;
    padding:0.3em;
    font-size:0.7em;
    
  }
  #datenschutzeinverständnis{
    word-wrap:break-word;
    margin:1em;
  
    
    
  }
  
  #nachricht::placeholder{
    color: rgb(24, 99, 194);
    font-family: Montserrat;
    
  }
  
  #vorname::placeholder ,#nachname::placeholder, #email::placeholder, #unternehmen::placeholder{
    color: rgb(24, 99, 194);
    font-family: Montserrat;
  }
  
  
  
  .msg{
    color: red;
    font-size:smaller;
  }
  }
  /* Home Wrapper All media 4*/
  
  @media screen and (min-width:1300px) and (min-height:600px){
    .wrapper_dark{
      position: relative;
      display:flex;
      flex-direction:column;
      justify-content:space-evenly;
      min-height:80vh;
      height:fit-content;
      align-items:center;
      text-align:center;
      font-family:Montserrat;
      z-index:1;
      color:white;
      background-color: rgb(18,32,58);
      font-size:1.5vw;
    }
    
    .wrapper_dark h1{
      color:white;
      font-size:2.5vw;
      margin:1.5em;
    }
    .wrapper_light{
      position: relative;
      display:flex;
      flex-direction:column;
      justify-content:space-evenly;
      min-height:70vh;
      height:fit-content;
      align-items:center;
      text-align:center;
      font-family:Montserrat;
      z-index:1;
      color: rgb(24, 99, 194);
      background-color: white;
      font-size:1.5vw;
    }
    
    .wrapper_light h1{
      color: rgb(24, 99, 194);
      margin:1.5em;
      font-size:2.5vw;
    }
   
    #energieversorgung .content {
      display:flex;
      align-items:center;
      text-align:center;
      padding: 0 10% 0 10%;
    }
    
    #energieversorgung .text{
      padding: 0 1% 0 1%;
      width:45%;
    }
    
    #energieversorgung .image{
      padding: 0 1% 0 1%;
      margin:0;
      width:50%;
    }
    #energieversorgung .image img{
      width:65%;
    }



    #mathematisch .content{
      display:flex;
      align-items:center;
      justify-content:center;
      padding: 0 10% 0 10%;
      
    
    }
    #mathematisch .image{
      width:50%;
      z-index:-1;
    }
    #mathematisch .image img {
      width:90%;
      height:90%;
    }
    #mathematisch .text{
      width:70%;
      display:flex;
      flex-direction:column;
      align-items:center;
      z-index:1;
    }
    #mathematisch .text p {
      width:80%;
  
    }
    .backgroundlogo2{
      position: absolute;
      left: -5vw;
      top:35vh;
      z-index:-10;
    }
    .backgroundlogo2 img{
      width:50vw;
    }
    #grauerKreis{
      position: absolute;
      left: -5vw;
      top:35vh;
      z-index:-11;
    }
    #grauerKreis img{
      width:50vw;
      
  
    }
    
  

  
  #fuenfgruende {
    position:relative;
    overflow-x:hidden;
  }
  #fuenfgruende h1{
    width:70%;
    text-align: center;
  }
 
  #fuenfgruende .list{
    display:flex;
    justify-content:space-between;
    width:100vw;
    align-items:center;
    height:35%;
    overflow-x:hidden;
  }

  #fuenfgruende .list h2{
    font-size:larger;
  }
  .fuenfgruende_box{
    background-color:rgb(24, 99, 194);
    width:calc(130vw / 3 - 2em);
    height:28vh;
    
    border-radius:2em;
    padding:2em 2em 3em 2em;
    box-shadow:0.3em 0.3em 0.3em black;
    display:flex;
    flex-direction:column;
    justify-content:center;
    margin:2em 0em 3em 0em;
  }
  .fuenfgruende_box h2{
    margin:1em 0 1em 0;
    font-weight:bold;
    font-size:large;
  }

  #fuenfgruende_click_right{
    background-color:transparent;
    width:calc(130vw / 3 - 2em);
    height:28vh;
    position:absolute;
    left:50vw;
    z-index:11;
    cursor:pointer;
    border-radius:2em;
    padding:2em 2em 3em 2em;
   
  }
  #fuenfgruende_click_left{
    background-color:transparent;
    width:calc(130vw / 3 - 2em);
    height:28vh;
    position:absolute;
    left:0;
    z-index:11;
    cursor:pointer;
    border-radius:2em;
    padding:2em 2em 3em 2em;
   
  }

  #fuenfgruende_box_1{
    position:sticky;
    left:25vw;
    scale:1;
    opacity:95%;
    z-index:9;
    transition:scale 1.5s,left 1.5s;
    
  }


  #fuenfgruende_box_2{
    position:absolute;
    left:50vw;
    scale:0.8;
    opacity:70%;
    z-index:8;
    cursor:pointer;
    transition:scale 1.5s,left 1.5s;
    
    
  }

  #fuenfgruende_box_3{
    position:absolute;
    left:40vw;
    scale:0.58;
    opacity:70%;
    z-index:7;
    cursor:pointer;
    transition:scale 1.5s,left 1.5s;
    
  }
  #fuenfgruende_box_4{
    position:absolute;
    left:10vw;
    scale:0.58;
    opacity:70%;
    z-index:7;
  
    transition:scale 1.5s,left 1.5s;
    
  }
  #fuenfgruende_box_5{
    position:absolute;
    left:0vw;
    scale:0.8;
    opacity:70%;
    z-index:8;
    cursor:pointer;
    transition:scale 1.5s,left 1.5s;
  }
 
  
  
  #vierschritte .content{
    display:flex;
    flex-direction:row;
    margin:0 2em;
    height: max-content;
    max-height:100vh;
    
  }
  #vierschritte .text{
    display:flex;
    flex-direction:column;
    align-items:center;
    margin:1em;
    box-shadow:5px 5px 10px grey;
    border-radius:2em;
    padding:1em;
    font-size:1vw;
    
  }
  #vierschritte .image{
  
  display:flex;
  justify-content:center;
  
  }
  #vierschritte img{
    width:40%;
  }
  
  #vierschritte h3{
    font-size:1.3vw;
  }
  
  
  #zahlen {
    height:60vh;
    min-height:60vh;
    font-size:1.7vw;
    
  }
  
  
  #zahlen h2 {
    font-weight:bold;
    color:rgb(84, 175, 230);
    
  }
  
  
  #zahlen .list{
    width:80%;
    display:flex;
    text-align:center;
    color: white;
    font-family:Montserrat;
    justify-content:space-between;
    padding: 0 0 2em 0 ;
  
  
  }
  #partner{
    height:fit-content;
    min-height:50vh;
    max-height:100vh;
  }
  
  #partner .bar{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap:5%;
    width:90vw;
    margin-bottom:2em;
  }
  
  #partner .bar .image{
    width:70%;
    
    
    display:flex;
    flex-direction:column;
    justify-content: center;
    
    margin:0 1em 0 1em;
  }
  #partner .bar .image img{
    width:90%;
    
    align-self:center;
    
    
  }
  #logo img{
    height:100%;
    width:100%;
    scale:0.7;
    align-items:center;
  }
  
  
  #team .text{
    display:flex;
    flex-direction:column;
    width:48%;
    
    
  
  }
  #team .content .image{
    width:50%;
    margin:3em 0 2em 0;
    display:flex;
    justify-content:space-around;
    
  }
  
  #team img{
    width:65%;
  }
  
  
  
  #kontaktformular .content{
    display:flex;
    
    
  }
  #kontaktformular .text{
    margin:0 5em 0 5em;
    width:50%;
    text-align:center;
  }
  #form{
    width:50%;
    height:min-content;
  }
  
  #vorname, #nachname, #email, #unternehmen{
    border-radius:0.8em;
    border-color:rgb(24, 99, 194);
    border-width:1px;
    width:calc(40% - 1em);
    margin:0.5em;
    color: rgb(24, 99, 194);
    font-family: Montserrat;
    padding:0.3em;
    font-size:0.7em;
  }
  #nachricht{
    border-radius:1em;
    border-color:rgb(24, 99, 194);
    border-width:1px;
    margin:0 1em;
    width:calc(80% - 1em);
    height:30%;
    min-height:100px;
    color: rgb(24, 99, 194);
    font-family: Montserrat;
    padding:0.3em;
    font-size:0.7em;
    
  }
   #datenschutzeinverständnis{
    word-wrap:break-word;
    margin:1em;
    
  }
  
  #nachricht::placeholder{
    color: rgb(24, 99, 194);
    font-family: Montserrat;
    
  }
  
  #vorname::placeholder ,#nachname::placeholder, #email::placeholder, #unternehmen::placeholder{
    color: rgb(24, 99, 194);
    font-family: Montserrat;
  }
  
  
  
  .msg{
    color: red;
    font-size:smaller;
  }
  }
  
  
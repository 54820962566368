
/* Wrapper Start*/
/* Wrapper Start media 1*/
@media screen and (max-width:520px){
    .wrapper_start{
      position:relative;
      display:flex;
      width:100vw;
      height:100vh;
      padding-left:10%;
      font-family:Montserrat;
      color: rgb(84,175,230);
      font-size:5vw;
      align-items:center;
      word-wrap:break-word;
    }
    .wrapper_start p{
      max-width:min-content;
    
    }
    #wrapper_start_focusword{
      color:white;
    }
    #focusword{
      color:rgb(24, 99, 194);
      text-transform:uppercase;
      position:absolute;
      visibility:hidden;
      
     
    }
    #wrapper_start_mainslogan{
      padding:2em 2em 0 0 ;
      max-width:80%;
      animation: move-from-left 2.5s forwards;
      
    }
    @keyframes move-from-left {
      0% {
        transform: translateX(-150px);
      }
      60% {
        transform: translateX(50px)
      }
      100% {
        transform: translateX(0px);
      }
    }
    @keyframes move-from-right{
      0% {
        transform: translate(15em,0em)
        
      }
      
      100% {
        transform: translateX(-0.2em,0em);
      }
    }
  
    @keyframes move-constantly{
      0%{
        transform: translate(-0.2em,0em);
      }
      50%{
        transform: translate(0.4em,0em)
      }
      100%{
        transform: translate(-0.2em,0em)
      }
    }
    
  }
  /* Wrapper Start media 2*/
  @media screen and (min-width:520px){
    .wrapper_start{
      position:relative;
      top:0;
      display:flex;
      width:100vw;
      height:100vh;
      padding-left:10%;
      font-family:Montserrat;
      color: rgb(84,175,230);
      font-size:5vw;
      align-items:center;
      
  
    }
    #wrapper_start_focusword{
      color:white;
    }
    #focusword{
      color:rgb(24, 99, 194);
      text-transform:uppercase;
      position:absolute;
      visibility:hidden;
  
      
     
    }
    #wrapper_start_mainslogan{
      padding:2em 2em 0 0;
      max-width:80%;
      animation: move-from-left 2.5s forwards;
      
    }
    @keyframes move-from-left {
      0% {
        transform: translateX(-150px);
      }
      60% {
        transform: translateX(50px)
      }
      100% {
        transform: translateX(0px);
      }
    }
    @keyframes move-from-right{
      0% {
        transform: translate(15em,0em)
        
      }
      
      100% {
        transform: translateX(-0.2em,0em);
      }
    }
  
    @keyframes move-constantly{
      0%{
        transform: translate(-0.2em,0em);
      }
      50%{
        transform: translate(0.4em,0em)
      }
      100%{
        transform: translate(-0.2em,0em)
      }
    }
    
    
  }
  /* Wrapper Start media 3*/
  @media screen and (min-width:820px){
    .wrapper_start{
      position:relative;
      width:100vw;
      height:100vh;
      padding-left:10%;
      font-family:Montserrat;
      color: rgb(84,175,230);
      font-size:3.9vw;
      align-items:center;
    }
    #wrapper_start_focusword{
      color:white;
    }
    #focusword{
      color:rgb(24, 99, 194);
      text-transform:uppercase;
      position:absolute;
      visibility:hidden;
      
      
     
    }
    #wrapper_start_mainslogan{
      padding:2em 2em 0 0;
      max-width:70%;
      animation: move-from-left 2.5s forwards;
      
    }
    @keyframes move-from-left {
      0% {
        transform: translateX(-150px);
      }
      60% {
        transform: translateX(50px)
      }
      100% {
        transform: translateX(0px);
      }
    }
    @keyframes move-from-right{
      0% {
        transform: translate(15em,0em)
        
      }
      
      100% {
        transform: translateX(-0.2em,0em);
      }
    }
  
    @keyframes move-constantly{
      0%{
        transform: translate(-0.2em,0em);
      }
      50%{
        transform: translate(0.4em,0em)
      }
      100%{
        transform: translate(-0.2em,0em)
      }
    }
    
   
  
    
  
  
  }
  
  
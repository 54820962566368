
/* Footer */
/* Footer media 1*/
@media screen and (max-width:460px){
    .footer{
      background-color: rgb(24,99,194);
      max-width:calc(100vw - 3em);
      position:relative;
      height:3em;
      padding: 0 2em 0 1em;
      display:flex;
      color:white;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-family: Montserrat-Light;
      font-weight: 500;
      text-decoration: none;
      align-items:center;
      justify-content:space-between;
      font-size:x-small;
    
    }
    .footer a:link, a:hover, a:visited {
      text-decoration:none;
      color:white;
      
    }
    .footerlinks{
      display:flex;
      width:50%;
      justify-content:space-between;
      color:white;
      
      text-transform:uppercase;
    
    }
    .footericons{
      display:flex;
      justify-content:flex-end;
      width:10%;
      scale:0.5;
    
    }
    
  }
  /* Footer media 2*/
  @media screen and (min-width:460px) and (max-width:660px){
    .footer{
      background-color: rgb(24,99,194);
      max-width:calc(100vw - 3em);
      position:relative;
      height:2.5em;
      
      padding: 0 2em 0 1em;
      display:flex;
      color:white;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-family: Montserrat-Light;
      font-weight: 500;
      text-decoration: none;
      align-items:center;
      justify-content:space-between;
      font-size:small;
    
    }
    .footer a:link, a:hover, a:visited {
      text-decoration:none;
      color:white;
      
    }
    .footerlinks{
      display:flex;
      width:60%;
      justify-content:space-between;
      color:white;
      padding-left:2em;
      text-transform:uppercase;
    
    }
    .footericons{
      display:flex;
      justify-content:flex-end;
      width:10%;
      margin:1em;
      scale:0.6;
     
    
    }
   
    
  }
  /* Footer media 3*/
  @media screen and (min-width:660px){
    .footer{
      background-color: rgb(24,99,194);
      max-width:calc(100vw - 3em);
      position:relative;
      height:2.5em;
      min-height:fit-content;
      padding: 0 2em 0 1em;
      display:flex;
      color:white;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size:small;
      font-family: Montserrat-Light;
      font-weight: 500;
      text-decoration: none;
      align-items:center;
      justify-content:space-between;
    
    }
    .footer a:link, a:hover, a:visited {
      text-decoration:none;
      color:white;
      
    }
    .footerlinks{
      display:flex;
      width:30%;
      justify-content:space-between;
      color:white;
      padding-left:2em;
      
      text-transform:uppercase;
    
    }
    .footericons{
      display:flex;
      justify-content:flex-end;
      width:10%;
      scale:0.6;
    
    }
    
  }
  
  
  
/* Navigation */
/* Navigation media 1*/
@media screen and (max-width: 660px) and (min-height:340px) {
    #nav {
      position: relative;
      text-decoration: none;
      z-index: 5;
    }
    #navbar {
      width:125px;
      position: absolute;
      margin-top:8px;
      top:0;
      right: 7.5%;
      background-color: rgb(24, 99, 194);
      height:2.5em;
      padding: 0 1em;
      border-radius: 3em;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: white;
      font-family: ArchivoNarrow;
      text-decoration: none;
      z-index: 5;
    }
  
    .menu-icon {
        display: flex;
        flex-direction: column;
        margin-left:1em;
    }
  
    .navlinks {
        display: none;
        z-index: 5;
    }
  
    .navlinks.active {
        display: flex;
        flex-direction:column;
    }
    .menu-line {
      min-width: 25px;
      max-width:25px;
      min-height: 3px;
      background-color: #fff;
      margin: 4px;
      /*transition: all 0.3s ease;*/
    }
    .navlinks.active #navbar{
      border-bottom-left-radius:0;
    }
    .navlinks.active {
      display: flex;
      flex-direction: column;
      align-items:center;
      background-color: rgb(24, 99, 194);
      padding: 2rem 1rem 1rem 1rem;
      position: absolute;
      top: 23px;
      left:0;
      border-radius: 3em;
      border-top-right-radius:0;
      border-top-left-radius:0;
      width: 125px;
      height:max-content;
      justify-content: space-between;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-family: Montserrat-Light;
      font-weight: 500;
      text-decoration: none;
      z-index:-1;
    }
    .navlinks.active.upper {
      display: flex;
      flex-direction: column;
      align-items:center;
      background-color: rgb(24, 99, 194);
      padding: 1rem 1rem 2rem 1rem;
      position: absolute;
      top: -150px;
      left:0;
      border-radius: 3em;
      border-bottom-right-radius:0;
      border-bottom-left-radius:0;
      width: 125px;
      height:max-content;
      justify-content: space-between;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-family: Montserrat-Light;
      font-weight: 500;
      text-decoration: none;
      z-index:-1;
    }
    .navlink{
      margin:0.4em 0;
  
    }
    
    .navlinks.active a {
      color: white;
    }
    #headerlogo {
      text-decoration: none;
      font-family: Cooper-Hewitt-Light;
      letter-spacing:1.2px;
    }
  }
  /* Navigation media 2*/
  @media screen and (max-height:340px) and (min-width:660px) {
    #nav {
      position: relative;
      text-decoration: none;
      z-index: 5;
    }
    #navbar {
      width:125px;
      position: absolute;
      margin-top:8px;
      top:0;
      right: 7.5%;
      background-color: rgb(24, 99, 194);
      height:2.5em;
      padding: 0 1em;
      border-radius: 3em;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: white;
      font-family: ArchivoNarrow;
      text-decoration: none;
      z-index: 5;
    }
  
    .menu-icon {
        display: flex;
        flex-direction: column;
        margin-left:1em;
    }
  
    .navlinks {
        display: none;
        z-index: 5;
    }
  
    .navlinks.active {
        display: flex;
        flex-direction:column;
    }
    .menu-line {
      min-width: 25px;
      max-width:25px;
      min-height: 3px;
      background-color: #fff;
      margin: 4px;
      /*transition: all 0.3s ease;*/
    }
    .navlinks.active #navbar{
      border-bottom-left-radius:0;
    }
    .navlinks.active {
      display: flex;
      flex-direction: column;
      align-items:center;
      background-color: rgb(24, 99, 194);
      padding: 2rem 1rem 1rem 1rem;
      position: absolute;
      top: 23px;
      left:0;
      border-radius: 3em;
      border-top-right-radius:0;
      border-top-left-radius:0;
      width: 125px;
      height:max-content;
      justify-content: space-between;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-family: Montserrat-Light;
      font-weight: 500;
      text-decoration: none;
      z-index:-1;
    }
    .navlinks.active.upper {
      display: flex;
      flex-direction: column;
      align-items:center;
      background-color: rgb(24, 99, 194);
      padding: 1rem 1rem 2rem 1rem;
      position: absolute;
      top: -150px;
      left:0;
      border-radius: 3em;
      border-bottom-right-radius:0;
      border-bottom-left-radius:0;
      width: 125px;
      height:max-content;
      justify-content: space-between;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-family: Montserrat-Light;
      font-weight: 500;
      text-decoration: none;
      z-index:-1;
    }
    .navlink{
      margin:0.4em 0;
  
    }
    
    .navlinks.active a {
      color: white;
    }
    #headerlogo {
      text-decoration: none;
      font-family: Cooper-Hewitt-Light;
      letter-spacing:1.2px;
    }
  }
  
  /* Navigation media 3*/
  @media screen and (min-width:660px) and (min-height:340px){
    #nav {
      position: relative;
      text-decoration: none;
      z-index: 5;
      color:white;
    }
    
    #navbar {
      position: absolute;
      top:calc( 100vh - 3.5em);
      left:0;
      background-color: rgb(24, 99, 194);
      width: 80vw;
      height: 2.5em;
      margin: 0.5em 0 0 7.5%;
      padding: 0 2em;
      border-radius: 3em;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: white;
      font-family: ArchivoNarrow;
      text-decoration: none;
      z-index: 5;
    }
    
    .navlinks {
      display: flex;
      width: 70%;
      justify-content: space-between;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-family: Montserrat-Light;
      font-weight: 500;
      text-decoration: none;
      z-index: 5;
      color:white;
    }
    
    .navbar a:link,
    a:hover,
    a:visited, a:-moz-any-link, a:any-link, a:current, a:enabled, a:focus {
      text-decoration: none !important;
      color: white !important;
      -moz-text-decoration-color: white  !important;
      -moz-text-decoration-style: none  !important;
      -webkit-text-fill-color: white  !important;
      
      
    }
    
    #headerlogo {
      text-decoration: none;
      font-family: Cooper-Hewitt-Light;
      letter-spacing:1.2px;
      
      
    }
    
    .logoName {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      font-size: large;
      text-decoration: none;
    }
    
    .menu-icon {
      display: none;
      cursor: pointer;
    }
    
    
  }
  /* Navigation media 4*/
  @media screen and (min-width:1200px) and (min-height:600px){
    #nav {
      position: relative;
      text-decoration: none;
      z-index: 5;
      color:white;
    }
    
    #navbar {
      position: absolute;
      top:calc( 100vh - 3.5em);
      left:0;
      background-color: rgb(24, 99, 194);
      width: 80vw;
      height: 2.5em;
      margin: 0.5em 0 0 7.5%;
      padding: 0 2em;
      border-radius: 3em;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: white;
      font-family: ArchivoNarrow;
      text-decoration: none;
      z-index: 5;
    }
    
    .navlinks {
      display: flex;
      width: 70%;
      justify-content: space-between;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-family: Montserrat-Light;
      font-weight: 500;
      text-decoration: none;
      z-index: 5;
      color:white;
    }
    
    .navbar a:link,
    a:hover,
    a:visited, a:-moz-any-link, a:any-link, a:current, a:enabled, a:focus {
      text-decoration: none !important;
      color: white !important;
      -moz-text-decoration-color: white  !important;
      -moz-text-decoration-style: none  !important;
      -webkit-text-fill-color: white  !important;
      
      
    }
    
    #headerlogo {
      text-decoration: none;
      font-family: Cooper-Hewitt-Light;
      letter-spacing:1.2px;
    }
    
    .logoName {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      font-size: larger;
      text-decoration: none;
    }
    
    .menu-icon {
      display: none;
      cursor: pointer;
    }
    
    
  }
  
  
  